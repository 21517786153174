<template>
    <v-dialog
        v-model="dialog"
    >
        <template v-slot:activator="{ on, attrs }">
            
            <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="get_analise_sugestoes"
            >   
                <v-icon
                small
                class="mr-2"
                color="grey"
                >
                mdi-pencil
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title primary-title>
            <v-toolbar
                color="grey"
                flat
                dark
                fixed
                class="ma-0 pa-0"
            >

                <v-toolbar-title>Editar #{{currentItem.id}}</v-toolbar-title>

                <v-spacer></v-spacer>

                
                <v-btn 
                    @click.stop="gravar" 
                    plain
                    icon
                    :loading='loading'
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
            
                <v-btn 
                    @click.stop="atualizar" 
                    plain
                    icon
                >
                    <v-icon>mdi-update</v-icon>
                </v-btn>
                <v-btn 
                        plain 
                        icon
                        @click="dialog=false"
                    ><v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            
                
            </v-card-title>
            <v-container>
                <v-card elevation="2" class="mb-3">
                    <v-card-text>
                        <v-container>
                            <v-form ref="form">
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >   
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Id:</v-col>
                                        <v-col>
                                        {{currentItem.id}}
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >   
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Colaborador:</v-col>
                                        <v-col v-if="currentItem.usuario">
                                        {{currentItem.usuario.username}}
                                        </v-col>
                                    </v-row>
                                </v-card>
                                
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Tipo:</v-col>
                                        <v-col>
                                            <v-chip
                                                label
                                                outlined
                                            >
                                                {{currentItem.tipo.text}}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Setor:</v-col>
                                        <v-col>
                                            <v-chip
                                                class="ma-1"
                                                label
                                                outlined
                                                v-for="i in currentItem.setor"
                                                :key="`${i.id}s`"
                                            >
                                                {{i.name}}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Registro:</v-col>
                                        <v-col>
                                        {{currentItem.registro}}
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Quando ocorreu:</v-col>
                                        <v-col>
                                        {{currentItem.created_at | formatData}}
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">A hora do ocorrido:</v-col>
                                        <v-col>
                                        {{currentItem.hora}}
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Envolvidos:</v-col>
                                        <v-col>
                                            <v-autocomplete
                                                v-model="currentItem.responsavel"
                                                multiple
                                                return-object
                                                :items='usuarios'
                                                item-text="nome"
                                                item-value="id"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card
                                    class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                        <v-col cols="2" class="font-weight-bold text-right">Resposta colaborador:</v-col>
                                        <v-col>
                                            <v-autocomplete
                                                v-model="currentItem.resultado"
                                                :items='resultados'
                                                return-object
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card-text
                                  class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                 <v-col cols="2" class="font-weight-bold text-right">Recebido em:</v-col>
                                        <v-col>

                                    {{currentItem.created_at | formatData }}</v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text
                                  class="pa-2"
                                    outlined
                                    tile
                                >
                                    <v-row>
                                 <v-col cols="2" class="font-weight-bold text-right">Ultima devolução para o colaborador:</v-col>
                                        <v-col>
                                    {{currentItem.updated_at | formatData }}</v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn 
                                        @click.stop="gravar" 
                                        plain
                                        color="green" 
                                        :loading='loading'
                                    >
                                        enviar<v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                
                                    <v-btn 
                                        @click.stop="atualizar" 
                                        plain
                                        color="orange"
                                    >
                                        atualizar<v-icon>mdi-update</v-icon>
                                    </v-btn>
                                    <v-btn 
                                        color="grey"
                                            plain 
                                            @click="dialog=false"
                                        >fechar<v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>           
            
            </v-container>
        
        </v-card>
        <v-snackbar
            v-model="loading"
            :color="cor"
            height="300"
            width="500"
        >
            {{ mensagem }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="fechaLoading"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name:'Edit',
    components:{},
    props:{
        currentItem:{}
    },
    data:()=>({
        dialog:false,
        //loading:false,
   //      show_atualiza_movimento:false,
        resultado:'',
        data_analises_sugestoes:{},
        conf:{},
        config_current:{},
        config_resultado:{
            method: 'get',
            url: 'informe/resultado/',
            headers: { 
                'Content-Type': 'application/json'
            },
        },
        //config_analise_status:{},
        config_analise_sugestoes:{},
        config_analise_sugestoes_post:{},
        money:{
            decimal:',',
            thousands:'.',
            prefix:'R$ ',
            precision:2,
            masked:false
        },
        usuario:JSON.parse(localStorage.getItem('cfgu')),
    }),
    computed:{
        ...mapState(['loading']),
        ...mapGetters(['analise_sugestoes','perm','usuarios','movimentos','resultados','cor','mensagem','adm','ava']),
        etapa(){
            return this.currentItem.avaliador!=''?false:true
        },
        resposta(){
            return this.resultados.filter(e=>e.macro>0)
        },
        devolvidoParaAdministrador(){
            return this.usuario.conf.devolvido_para_adm == this.currentItem.resultado.id
        }
    },
    methods:{
        ...mapActions(['btnLoading']),
        gravar(){
            this.$store.dispatch('btnLoading',true)
            this.$store.dispatch('cor','green lighten-2')
            this.$store.dispatch('lista',{
                method:'put',
                url:this.currentItem.update_url,
                data:this.currentItem,
                instancia:'ouvidoria'
            })
            this.atualizar()
        },
        atualizar(){
            this.$store.dispatch('lista',{url:'informe/ouvidorias/'})
        },
        fecha(){
            this.$store.dispatch('btnLoading',false)
        },  
        get_analise_sugestoes(){
            this.$store.dispatch('listaAnaliseSugestoes',this.config_analise_sugestoes)
            // this.show_atualiza_movimento = this.currentItem.resultado.id!=this.conf.conf.enviado_para_avaliador
            // @todo - refazer o vetor do param papel, pra receber o valor a partir do endpoint de config
            this.$store.dispatch('usuarios',{url:'contas/usuarios-list/?papel=[1,2]'})
            this.$store.dispatch('resultados',{url:'informe/resultados/?obj=OUVIDORIA'})
            //this.$store.dispatch('movimentos',{url: `informe/movimentos-sugestoes/?sugestao=${this.currentItem.id}`})
        },
        post_analise_sugestoes(){
            this.$store.dispatch('lista',this.config_analise_sugestoes_post)
            this.data_analises_sugestoes.text=''
        },
        fechaLoading(){
            this.$store.dispatch('btnLoading',false)
        },
        atualizarMovimento(val,met){
            this.$store.dispatch('btnLoading',true)
            let metodo = 'post'
            let url = ''
            let data = {}
            if(met=='adm'){
                url = 'informe/movimentos-sugestoes/'
                data.sugestao = this.currentItem.id
                data.resultado = this.conf.conf.devolvido_para_adm
                metodo = 'post'
            }else if(met=='ava'){
                url = 'informe/movimentos-sugestoes/'
                data.sugestao = this.currentItem.id
                data.resultado = this.conf.conf.enviado_para_avaliador
                metodo = 'post'
            }else if(met=='usuario'){
                url = 'informe/movimentos-sugestoes/'
                data.sugestao = this.currentItem.id
                data.resultado = this.resultado.id
                metodo = 'post'
            }else{
                data=val
                url = data.update_url
                metodo = 'put'

            }
            let config = {
                method: metodo,
                url: url,
                data : data,
                loading:true,
            }
            this.$store.dispatch('movimentos',config)
        },
        merge(val){
            this.$store.dispatch('btnLoading',true)
            this.$store.dispatch('lista',{
                method: 'put',
                url: val.update_url,
                data : val,
                loading:false,
            })
        },
        
    },
    mounted(){
        // this.$store.dispatch('perm')
        this.$store.dispatch('adm', this.usuario)
        this.$store.dispatch('ava', this.usuario)
        this.$store.dispatch('btnLoading', false)
        this.config_current = {
            method: 'put',
            url: 'informe/sugestoes/'+this.currentItem.id+'/',
            data : this.currentItem,
            loading:true
        }
        this.conf = JSON.parse(localStorage.getItem('cfgu'))
        this.$store.dispatch('usuarios',{url: 'contas/usuarios/'})
        this.$store.dispatch('resultados',{url: 'informe/resultados/'})
        this.data_analises_sugestoes.sugestao = this.currentItem
        this.config_analise_sugestoes_post = {
            method: 'post',
            url: 'informe/analises-sugestoes/',
            data : this.data_analises_sugestoes,
            loading:true,
        }
        this.config_analise_sugestoes =    {
            method: 'get',
            url: 'informe/analises-sugestoes/?sugestao='+this.currentItem.id,
            instancia:'analise-sugestoes',
        }
    },
}
</script>